import React, { useMemo } from 'react';
import { useRootSelector } from '../../../../../../store/hooks';
import {
  AggregatedProductModule,
  ProductModuleType,
  RowsOptions,
} from '../../AggregatedProductModule';
import { ProductsGrid } from '../../../productFilter/products';
import VideoCard from '../../../productFilter/cards/VideoCard';
import {
  DiscoveryFeaturedTypes,
  DiscoveryStreamlyVideo,
} from '../../../../../../store/features/discovery';
import { selectAudienceHubPrimaryTopic } from '../../../../audienceHub/store/features/options';
import { getDiscoveryContentByType, isFilterEnabled } from '../../../helpers';
import {
  DiscoveryContentTypes,
  HubProductFilterPosition,
} from '../../../../../../constants';
import { PropsWithTestId } from '../../../../../../types';
import { useHubContext } from '../../../context/hubContext';
import { useStreamlyVideoParams } from '../../../hooks';
import StreamlyVideoModal from '../../../StreamlyVideoModal';

export interface StreamlyVideoModuleType extends ProductModuleType {
  featuredRowsCount: keyof typeof RowsOptions;
}

export interface StreamlyVideoModuleProps {
  module: StreamlyVideoModuleType;
}

const defaultProps = {
  testId: 'hub-streamly-video-module',
};

export default function StreamlyVideoModule(
  props: PropsWithTestId<StreamlyVideoModuleProps>,
) {
  const { module, testId } = props;
  const { brandName } = useHubContext();
  const { params, handleCleanParams, handleUpdateParams } =
    useStreamlyVideoParams();
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);

  const {
    featuredRowsCount,
    discovery: { records = [] } = {},
    searchQueryData,
    filterPosition,
  } = module;
  const FILTER_LEFT = filterPosition === HubProductFilterPosition.LEFT;
  const FILTER_TOP = filterPosition === HubProductFilterPosition.TOP;
  const filterEnabled = isFilterEnabled(searchQueryData.facetsConfig);
  const showLeftFilter = filterEnabled && FILTER_LEFT;
  const showTopFilter = filterEnabled && FILTER_TOP;

  const defaultValues = useMemo(
    () => ({
      sectors: [],
      topics: [],
      accessTypes: [],
      createdTimeFrame: [],
      mainFilterTopic,
    }),
    [mainFilterTopic],
  );

  const { results = [], count: resultsCount = 0 } =
    getDiscoveryContentByType<DiscoveryStreamlyVideo>(
      DiscoveryContentTypes.STREAMLY_VIDEO,
      records,
    );

  const videos = useMemo(() => {
    return results
      .filter((item) => item.featuredType !== DiscoveryFeaturedTypes.LARGE)
      .slice(0, RowsOptions[featuredRowsCount] * (showLeftFilter ? 3 : 4));
  }, [results, featuredRowsCount, showLeftFilter]);

  const largeProducts = useMemo(() => {
    return results.filter(
      (item) => item.featuredType === DiscoveryFeaturedTypes.LARGE,
    );
  }, [results]);

  return (
    <AggregatedProductModule
      module={module}
      moduleName="streamly-videos"
      brandName={brandName}
      defaultValues={defaultValues}
      resultsCount={resultsCount}
      showLeftFilter={showLeftFilter}
      showTopFilter={showTopFilter}
      bottomShowMoreButtonLabel="Search All Video"
      topShowMoreButtonLabel="Browse All Video"
      showMoreButtonURL="/videos"
      testId={testId}
    >
      <ProductsGrid<DiscoveryStreamlyVideo>
        products={videos}
        featuredProducts={largeProducts}
        renderFeaturedProduct={(product) => (
          <VideoCard
            data={product}
            cardType="list"
            showFilter={filterEnabled}
            testId="featured-video-card"
            onOpen={handleUpdateParams}
          />
        )}
        renderProduct={(product) => (
          <VideoCard data={product} onOpen={handleUpdateParams} />
        )}
        productsCountPerRow={showLeftFilter ? 'three' : 'four'}
      />
      {params && (
        <StreamlyVideoModal
          open={!!params}
          videoParams={params}
          onClose={handleCleanParams}
        />
      )}
    </AggregatedProductModule>
  );
}

StreamlyVideoModule.defaultProps = defaultProps;
